import "./index.css"
import React from "react";
import ReactDOM from "react-dom/client";
import firebaseConfig from "./pages/auth/firebase.config.js";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from 'react-redux'
import { store, persistor } from './redux/Store.js'
import { Spinner } from '@chakra-ui/react';
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate Loading={<Spinner color='#808080' className='h-16 w-16'/>} persistor={persistor}>
        <App />
      </PersistGate>   
    </Provider>
  </React.StrictMode>
);
