import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: [],
    userInfo: null,
};

export const slice = createSlice( {
    name: "drenom",
    initialState,
    reducers: {
        addToCart: ( state, action ) => {
            const item = state.products.find( ( item ) => item.id === action.payload.id )  
            if ( item ) {
                item.quantity = action.payload.quantity;
            } else (
                state.products.push( action.payload )
            )
        },
        increaseQuantity: ( state, action ) => {
            const item = state.products.find( ( item ) => item.id === action.payload )
            if ( item ) {
                item.quantity++     
            }
        },
        decreaseQuantity: ( state, action ) => {
            const item = state.products.find( ( item ) => item.id === action.payload )
            if ( item.quantity === 1 ) {
                item.quantity = 1
            } else {
                item.quantity--
            }
        },
        deleteProduct: ( state, action ) => {
            state.products = state.products.filter( ( item ) => item.id !== action.payload );
        },
        resetCart: ( state ) => {
            state.products = []
        },
        setUserInfo: ( state, action ) => {
            state.userInfo = action.payload
        },
        userSignOut: ( state ) => {
            state.userInfo = null
        }
    }
} );

export const {
    addToCart,
    deleteProduct,
    resetCart,
    decreaseQuantity,
    increaseQuantity,
    setUserInfo,
    userSignOut
} = slice.actions;
export default slice.reducer;