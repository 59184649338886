import React from 'react'
import { Link } from 'react-router-dom';
import BannerImage from '../../assets/images/banner-image.png';
import Background from '../../assets/images/background1.png';


const Banner = () => {
  return (
    <div>
      <div className='flex lg:flex-row-reverse md:flex-col sm:flex-col justify-between lg:mb-24 md:mb-20 sm:mb-20 lg:mx-24 sm:mx-7'>
        <div className='relative md:mt-1 sm:mt-6 bg-no-repeat lg:w-[50vw] md:w-[100vw] md:mb-96 sm:mb-96 lg:-mr-48 md:ml-12'>
          <img src={Background} alt="background" className='z-10 absolute md:w-[80%] md:mx-auto sm:w-[80%] sm:mx-16 '/>
          <img src={BannerImage} alt="Banner" className='z-20 absolute md:w-[100%] md:left-10 sm:w-[100%] sm:left-24'/>
        </div>
        <div className='lg:w-[50vw] md:w-[90vw] sm:w-[90vw] lg:mt-24 lg:mb-32 md:mt-96 sm:mt-32'>
          <h1 className='lg:text-5xl md:text-3xl text-[#3B3B3B] lg:leading-snug md:leading-snug font-bold lg:w-[90%] md:w-[90%]'>Buy Foreign Used, and Pay Locally</h1>
          <p className=' text-gray-500 pt-6 lg:text-xl md:text-lg sm:text-lg'>Shop for foreign used products, view the prices in your local currency, pay locally, and have them delivered to your doorstep</p>
          <Link to="/demo">
            <button className='font-heading_font text-white bg-secondary_color hover:bg-hover_color cursor-pointer mt-12 md:mt:8 sm:mt-8 h-16 lg:w-80 md:w-full sm:w-full outline-none rounded-lg text-lg'>
            Try Demo
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Banner