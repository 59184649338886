import React from 'react'
import { Link } from 'react-router-dom';
import Facebook from '../assets/images/facebook.png';
import Twitter from '../assets/images/twitter.png';
import Instagram from '../assets/images/instagram.png';
import Linkedin from '../assets/images/linkedin.png';


const Footer = () => {

let currentDate = new Date();
let currentYear = currentDate.getFullYear();

  return (
    <div>
          <div className='bg-white lg:px-24 md:px-7 sm:px-7 py-4 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]'>
              <div className='flex place-content-between text-heading_font'>
                  <div>
                    <Link to="/"><p className='text-lg text-secondary_color font-logo_font cursor-pointer'>dremci</p></Link> 
                    <p className='font-heading_font text-gray-500 lg:text-base md:text-sm sm:text-sm'>Copyright &copy; {currentYear} Drenom</p>
                  </div>
                  <div className='leading-relaxed'>
                    <h1 className='lg:text-base md:text-sm sm:text-sm font-bold font-heading_font'>Quick Links</h1>
                    <Link to="/demo"><p className='font-heading_font mt-1 text-gray-500 lg:text-base md:text-sm sm:text-sm'>Demo</p></Link>
                    <Link to="/cart"><p className='font-heading_font mt-1 text-gray-500 lg:text-base md:text-sm sm:text-sm'>Shopping Cart</p></Link>
                  </div>
                  <div>
                        <div className='flex space-x-3'>
                            <img src={Facebook} alt='Facebook' className='w-7'/>
                            <img src={Twitter} alt='Twitter' className='w-7'/>
                            <img src={Instagram} alt='Instagram' className='w-7'/>
                            <img src={Linkedin} alt='Linkedin' className='w-7'/>
                        </div>
                      <p className='pt-2 font-heading_font text-gray-500 lg:text-base md:text-sm sm:text-sm'>inquiry@dremci.com</p>
                  </div>
              </div>
          </div>      
    </div>
  )
}

export default Footer