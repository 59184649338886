import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import { userSignOut, resetCart } from '../redux/Slice';
// import { GeoLocation } from '../api/GeoLocation';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';


const Header = () => {
    const auth = getAuth();
    const dispatch = useDispatch();
    // const geoLocation = GeoLocation();
    const products = useSelector( ( state ) => state.drenom.products );
    const userInfo = useSelector( ( state ) => state.drenom.userInfo );
    const [ cartTotal, setCartTotal ] = useState();
    // const [ message, setMessage ] = useState(); 
    
    useEffect( () => {
        let Total = 0;
        products.map( ( item ) => {
          Total += item.quantity;
          return setCartTotal( Total );
        }, [ products ] );
    } );
   
    const handleLogout = () => {
        signOut( auth )
            .then( () => {
                dispatch( userSignOut() )
                dispatch( resetCart() );
                document.location.reload();
            }).catch((error) => {
            // An error happened.
            });
    }

    // useEffect( () => {
    //     const varMessage = geoLocation === "Nigeria" && "Kenya" && "South Africa" ? 
    //         <p className='text-white text-base text-center'><Link to="/signup" className='underline'>Sign up </Link>today and enjoy a special offer on our launch date!
    //         </p> :
    //         <p className='text-white text-base text-center'><Link to="/signup" className='underline'>Sign up </Link>today as a seller and enjoy a special offer on our launch date!
    //         </p>
    //     setMessage(varMessage)
    // },[geoLocation] );

    
    const [ isOpen, setIsOpen ] = useState( false );

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };
    
    return (
        <div className='h-auto fixed top-0 w-full z-50'>
          {/* <div className='bg-[#647C90] py-3 px-16 h-auto'>
                {
                    message
                // }
          </div> */}
          <nav className="bg-white p-3 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
            <div className="container mx-auto flex items-center justify-between">
               <div className='font-logo_font cursor-pointer'>
                    <Link to='/'>
                        <p className='text-xl text-secondary_color'>dremci</p> 
                    </Link>
                </div>

                {/* Hamburger menu for small screens */}
                <div className='flex justify-center'>
                    <div className="lg:hidden md:hidden sm:mr-5">
                    <button
                        onClick={toggleNavbar}
                        className="text-text_color focus:outline-none focus:border-none hover:text-hover_color"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16m-7 6h7"
                            ></path>
                        </svg>
                    </button>
                </div>

                {/* Navbar links */}
                <div
                    className={`${
                        isOpen ? 'block' : 'hidden'
                    } lg:flex lg:items-center lg:w-auto md:flex md:items-center md:w-auto`}
                >
                    <div className="flex flex-col lg:flex-row md:flex-row">
                        <Link
                            to="/demo"
                        >
                            <p className='text-text_color font-semibold hover:text-hover_color cursor-pointer text-base font-heading_font py-2'>Demo</p> 
                        </Link>
                                {userInfo ?
                                    <>
                                        <p className='text-text_color font-semibold hover:text-hover_color cursor-pointer text-base sm:border-none font-heading_font py-2 lg:px-8 md:px-8'><PersonIcon />Hello</p>
                                        <p onClick={() => handleLogout()} className='text-text_color font-semibold hover:text-hover_color cursor-pointer text-base sm:border-none font-heading_font py-2 lg:pr-6 md:pr-6'><LogoutIcon />Logout</p>
                                    </>
                                    :
                                    <>
                                        <Link to="/login">
                                            <p className='text-text_color font-semibold hover:text-hover_color cursor-pointer text-base sm:border-none font-heading_font py-2 lg:px-9 md:px-9'>Login</p>
                                        </Link>
                                        <Link to="/signup">
                                            <div className='lg:hover:bg-hover_color md:hover:bg-hover_color  lg:bg-secondary_color md:bg-secondary_color sm:font-semibold cursor-pointer lg:border md:border lg:px-8 md:px-8 lg:mr-4 md:mr-4 py-2 outline-none rounded-lg'>
                                                <p className='lg:text-white md:text-white sm:text-text_color lg:text-sm md:text-sm font-heading_font lg:hover:text-white md:hover:text-white sm:hover:text-hover_color'>Sign Up</p>
                                            </div>
                                        </Link>
                                    </>
                                }
                        
                        </div>
                </div>
                <Link
                    to="/cart"
                    className="sm:mt-2 md:mt-0 lg:mt-0 lg:py-2 md:py lg:pl-4 md:pl-4 lg:border-l-2 md:border-l-2 sm:border:none lg:ml-0 md:ml-0 sm:ml-20"
                >
                    <p className='relative cursor-pointer flex items-center text-text_color font-heading_font ml-5 hover:text-hover_color'>
                        Cart
                        <ShoppingCartIcon />
                        {products.length > 0 ?
                        <span className='absolute text-xs -top-2 lg:-right-3 md:-right-3 sm:-right-4 text-white rounded-full bg-secondary_color w-6 h-6 text-center pt-1 font-font'>{products.length > 0 ? cartTotal : 0}</span> : null}
                    </p>
                </Link>
                </div>
            </div>
        </nav>
    </div>
  )
}

export default Header;


