import React, { useEffect, useRef, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { motion, useInView, useAnimation } from "framer-motion";

const AccordionItem = ({ title, content, isOpen, index, onToggleAccordion }) => {

  const toggleAccordion = () => {
    onToggleAccordion(index);
  };

  return (
    <div>
      <div
        className="flex justify-between items-center py-4 cursor-pointer border-b border-gray-300"
        onClick={toggleAccordion}
      >
        <h2 className="text-xl font-semibold text-text_color">{title}</h2>
        <span>{isOpen ? <RemoveCircleIcon style={{ color: '#3B3B3B"', fontSize: '2rem' }} /> : <AddCircleIcon style={{ color: '#3B3B3B"', fontSize: '2rem' }} />}</span>
      </div>
      {isOpen && <div className="p-4 border-2-red text-gray-600 text-lg bg-slate-100">{content}</div>}
    </div>
  );
};

const Faq = () => {

  const [openIndex, setOpenIndex] = useState(null);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="w-full-md lg:mx-24 md:mx-7 sm:mx-7 rounded-3xl mb-32 lg:-mt-10 md:-mt-28 sm:-mt-44 text-text_color" ref={ref}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },

        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.25 }}
      >
        <AccordionItem
          title="What is Dremci all about?"
          content="Drenom is a marketplace that enables Africans to shop for foreign used products in their local currencies and have their orders delivered to their doorstep in Africa. Additionally, it provides a platform for sellers from outside Africa to list their products"
          isOpen={openIndex === 0}
          index={0}
          onToggleAccordion={toggleAccordion}
        />
        <AccordionItem
          title="So, any African can shop on Dremci"
          content="Yes, but we currently support online shoppers from Nigeria. We plan to gradually expand our services to include every African country"
          isOpen={openIndex === 1}
          index={1}
          onToggleAccordion={toggleAccordion}
        />
        <AccordionItem
          title="Who can sell on Dremci?"
          content="Only sellers residing outside Africa, specifically in the United States, Canada and United Kingdom, can sell on our platform"
          isOpen={openIndex === 2}
          index={2}
          onToggleAccordion={toggleAccordion}
        />
        <AccordionItem
          title="In what currency are sellers paid?"
          content="Sellers will be paid in their own currency. For instance, sellers in the United States are paid in US dollars"
          isOpen={openIndex === 3}
          index={3}
          onToggleAccordion={toggleAccordion}
        />
        <AccordionItem
          title="Who fulfill the orders?"
          content="Every orders are fulfilled by our dedicated shipping partners"
          isOpen={openIndex === 4}
          index={4}
          onToggleAccordion={toggleAccordion}
        />
        {/* Add more AccordionItems as needed */}
      </motion.div>
    </div>
  );
};

export default Faq;
