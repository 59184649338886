import React, {useEffect, useRef} from 'react';
import Buyer from '../../assets/images/buyer.jpg';
import { motion, useInView, useAnimation } from "framer-motion";
import { Link } from 'react-router-dom';

const SellerInfo = () => {

const ref = useRef( null );
const isInView = useInView( ref, { once: false } );
const mainControls = useAnimation();

useEffect( () => {
  if ( isInView ) {
    mainControls.start( "visible" );
  }
}, [isInView, mainControls] );

  return (
      <div ref={ref}>
        <motion.div
            variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
            }}
            initial= "hidden"
            animate= {mainControls}
            transition={{ duration: 0.5, delay: 0.25 }}
            >
                <div className='lg:flex lg:flex-row md:flex-none sm:flex-none lg:mx-24 md:mx-7 sm:mx-7 pt-32 pb-40'>
                    <div className='lg:w-[50%] lg:h-40'>
                        <img src={Buyer} alt="web" className='rounded-3xl'/> 
                    </div>
                    <div className='lg:flex lg:flex-col md:flex-none sm:flex-none mb-24 lg:w-[50%] md:w-full sm:w-full lg:pl-16'>
                        <h1 className='lg:mt-16 md:mt-8 sm:mt-8 lg:text-4xl md:text-3xl sm:text-3xl font-extrabold text-text_color'>Sell your Used Products</h1>
                        <p className='text-xl text-gray-500 mt-8'>Earn extra income by selling your used products from the comfort of your home</p>
                        <Link to="/signup">
                            <button className='font-heading_font text-white bg-secondary_color hover:bg-hover_color cursor-pointer lg:mt-16 md:mt:10 sm:mt-10 h-16 lg:w-80 md:w-full sm:w-full outline-none rounded-lg text-lg'>
                                Sign Up Today
                            </button>
                        </Link>
                    </div>
                </div>
        </motion.div>
          
    </div>
  )
}

export default SellerInfo