import React, {useEffect, useRef} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Ng from "../../assets/images/dremci-ng.png";
import Ke from "../../assets/images/dremci-ke.png";
import Za from "../../assets/images/dremci-za.png";
import { motion, useInView, useAnimation } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleChevronLeft, faCircleChevronRight } from '@fortawesome/free-solid-svg-icons'

const Slider = () => {

const ref = useRef( null );
const isInView = useInView( ref, { once: false } );
const mainControls = useAnimation();

useEffect( () => {
  if ( isInView ) {
    mainControls.start( "visible" );
  }
}, [ isInView ] );

  return (
    <div className='lg:mx-24 md:mx-7 sm:mx-7 py-18 z-10' ref={ref}>      
      <motion.div
        variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
            
          }}
        initial= "hidden"
          animate= {mainControls}
        transition={{ duration: 0.5, delay: 0.25 }}
      >
        <Carousel data-bs-theme="dark" pause={false} touch={true} prevIcon={<FontAwesomeIcon icon={faCircleChevronLeft} className='carousel-icon' size="4x"/>} nextIcon={<FontAwesomeIcon icon={faCircleChevronRight} className='carousel-icon' size="4x"/>} >
          <Carousel.Item interval={3000}>
            <img src={Ke} alt="ke" className='rounded-3xl'/>
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img src={Ng} alt="ng" className='rounded-3xl'/>
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img src={Za} alt="za" className='rounded-3xl'/>
            <Carousel.Caption >
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
    </motion.div>
        
    </div>
  )
}

export default Slider;