import React, {useEffect, useRef} from 'react';
import Banner from "./Banner";
import Header from "../../components/Header";
import SellerInfo from './SellerInfo';
import Footer from '../../components/Footer';
import Faq from './Faq';
import { ScrollRestoration } from 'react-router-dom';
import { motion, useInView, useAnimation } from "framer-motion";
import Slider from './Slider';

const Home = () => {

const ref = useRef( null );
const isInView = useInView( ref, { once: false } );
const mainControls = useAnimation();

useEffect( () => {
  if ( isInView ) {
    mainControls.start( "visible" );
  }
}, [isInView, mainControls] );

  return (
    <div ref={ref} className='pt-24'>
      <ScrollRestoration />
      <Header />
      <motion.div
        variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
            
          }}
        initial= "hidden"
        animate= {mainControls}
        transition={{ duration: 0.5, delay: 0.25 }}
      >
          <Banner />
          <Slider />
          <SellerInfo />
          <Faq />
          <Footer />
    </motion.div>
    </div>
  )
}

export default Home
