import "./App.css";
import Routers from "./routers/Routers";

function App () {
  return (
    <div className="font-font overflow-x-hidden">
      <Routers />
    </div>
  );
}

export default App;
