// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDezkydcrJI9a7Vt3DrlbXWG2Of-9-Oo2I",
  authDomain: "drenom-19207.firebaseapp.com",
  projectId: "drenom-19207",
  storageBucket: "drenom-19207.appspot.com",
  messagingSenderId: "117098389509",
  appId: "1:117098389509:web:1b252d1e9103c8d2bc7c6c",
  measurementId: "G-T8B2NBRPHN"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export default firebaseConfig
// const analytics = getAnalytics(app); 