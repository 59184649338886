import React, { Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom"; 
import Home from "../pages/home/Home.js";
import { useSelector } from 'react-redux';
// import ProductDetail from "../pages/productDetail/ProductDetail.js";
import { ProductsData } from "../data/ProductsData.js";
const Cart = React.lazy( () => import( '../pages/cart/Cart.js') );
const Login = React.lazy( () => import( '../pages/auth/Login.js' ) );
const Registration = React.lazy( () => import( '../pages/auth/Registration.js' ) );
const Demo = React.lazy( () => import( '../pages/demo/Demo.js' ) );
const Faq = React.lazy(() => import('../pages/home/Faq.js'));
const Checkout = React.lazy(() => import('../pages/checkout/Checkout.js'));
const InvalidPage = React.lazy(() => import('../components/InvalidPage.js'));

const Routers = () => {

  const userInfo = useSelector( ( state ) => state.drenom.userInfo );

  const router = createBrowserRouter(
    createRoutesFromElements(
    <Route>
          <Route index element={<Home />} />
          <Route path="/cart" element={
              <Suspense fallback={<div></div>}>
                <Cart />
              </Suspense>
          } />
          <Route path="/demo" loader={ProductsData} element={
              <Suspense fallback={<div></div>}>
                <Demo />
              </Suspense>
          } />
           <Route path="/faq" element={
              <Suspense fallback={<div></div>}>
                <Faq />
              </Suspense>
          }/>
          {/* <Route path="/products/:id" element={
              <Suspense fallback={<div></div>}>
                <ProductDetail />
            </Suspense>
          }/> */}
          <Route path="checkout" element={
              <Suspense fallback={<div></div>}>
                {userInfo ? <Checkout /> : ( <Navigate replace to="/login" /> )}
              </Suspense>
          }/>
          
          <Route path="login" element={
              <Suspense fallback={<div></div>}>
                <Login />
              </Suspense>
          }/>
          
          <Route path="signup" element={
              <Suspense fallback={<div></div>}>
                <Registration />
            </Suspense>
          } />
          <Route path="*" element={
            <Suspense fallback={<div></div>}>
                <InvalidPage />
            </Suspense>
          }/>
    </Route>
  ))
    
    return (
      <div>
        <RouterProvider router={router}></RouterProvider>
      </div>
    )
}

export default Routers