import Playstation from "../assets/images/playstation4.png";
import Applewatch from "../assets/images/applewatch.png"; 
import iPhone13Pro from "../assets/images/iphone13pro.png";
import Samsungtv from "../assets/images/samsungtv.png";
import Dishwasher from "../assets/images/dishwasher.png";
import Camera from "../assets/images/camera.png";
import Printer from "../assets/images/printer.png";
import Macbook from "../assets/images/macbook2019.png";
import Podcast from "../assets/images/podcast.png";
import DJ from "../assets/images/dj.png";

export function ProductsData () {
    
    const products = [
        {
            id: 1,
            title: "Play-Station 4 PS4",
            price: 240,
            description: "X86-64 AMD Jaguar 8 Cores Processor; 1.84 TFLOPS, AMD Radeon Based Graphics Engine",
            image: Playstation
        },
        {
            id: 2,
            title: "iPhone 13 Pro, 256GB",
            description: "Wondersful counter table that can last for years with 5years money back guarantee",
            price: 230,
            image: iPhone13Pro
        },
        {
            id: 3,
            title: "Apple Watch Series 9",
            description: "Smartwatch with Midnight Aluminum Case with Midnight Sport Band M/L. Fitness Tracker, Blood Oxygen & ECG Apps, Always-On Retina Display",
            price: 220,
            image: Applewatch
        },
        {
            id: 4,
            title: "Apple MacBook Pro",
            description: "Ninth-generation 6-Core Intel Core i7 Processor",
            price: 280,
            image: Macbook
        },
        {
            id: 5,
            title: "Denon DJ PRIME 4",
            description: "Wondersful counter table that can last for years with 5years money back guarantee",
            price: 1500,
            image: DJ
        },
        {
            id: 6,
            title: "Canon DSLR Camera",
            description: "The EOS Rebel T7 camera can help mobile device users take their photography to the next level. With a large 24.1 Megapixel CMOS sensor, fast autofocus, built-in wireless connectivity and more, it combines fantastic features with easy operation for high-quality images you'll be proud to share.",
            price: 250,
            image: Camera
        },
        {
            id: 7,
            title: "Brother Wireless Printer",
            description: "The Brother MFC-L3720CDW Multifunction Printer features print speeds up to 19 ppm(2) and scan speeds up to 29/22 ipm(3) (bk/cl)",
            price: 220,
            image: Printer
        },
        {
            id: 8,
            title: "MAONO Podcast Mixer",
            description: "Works as an audio mixer station. Integrating audio mixing equipment into 1 comprehensive solution",
            price: 105,
            image: Podcast
        },
        // {
        //     id: 9,
        //     title: "SAMSUNG Smart TV",
        //     description: "Smart TV - Get to your entertainment the faster, easier, and more intelligent way. Easily access your streaming services all in one place using the Samsung Remote Control",
        //     price: 140,
        //     image: Samsungtv
        // },
        // {
        //     id: 10,
        //     title: "Hermitlux Dishwasher",
        //     description: "hermitlux Countertop Dishwasher increases the convenience of your life, even if the space is small, it can be placed in your kitchen.",
        //     price: 110,
        //     image: Dishwasher
        // },
    ];
    return products;
}